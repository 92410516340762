var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_c('CIcon',{attrs:{"name":"cil-user"}}),_vm._v(" 用户管理 "),_c('div',{staticClass:"card-header-actions"},[(_vm.lginfo.permUser==1)?_c('CButton',{attrs:{"size":"sm","color":"info","variant":"outline"},on:{"click":function($event){_vm.newshow=true}}},[_vm._v(" 新建用户 ")]):_vm._e()],1)],1),_c('CCardBody',[_c('CDataTable',{attrs:{"hover":true,"striped":true,"border":true,"small":true,"fixed":true,"fields":_vm.fields,"items":_vm.items},scopedSlots:_vm._u([{key:"aid",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(item.aid)+" ")])]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pthands",staticStyle:{"color":"blue"},on:{"click":function($event){return _vm.$router.push(("/user/info/" + (item.aid)))}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"nick",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pthands",staticStyle:{"color":"blue"},on:{"click":function($event){return _vm.$router.push(("/user/info/" + (item.aid)))}}},[_c('myavatar',{attrs:{"src":item.avat,"nick":item.nick,"imgw":"18px"}})],1)]}},{key:"created",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.$dateFmt(item.created))+" ")])]}},{key:"active",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.active==1?'已激活':'未激活')+" ")])]}},{key:"btns",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"color":"info","square":"","size":"sm"},on:{"click":function($event){return _vm.$router.push(("/user/info/" + (item.aid)))}}},[_vm._v(" 详细 ")]),(_vm.lginfo.permUser==1)?_c('CButton',{staticStyle:{"margin-left":"10px"},attrs:{"color":"warning","square":"","size":"sm"},on:{"click":function($event){_vm.permSel=true;_vm.permUid=item.id}}},[_vm._v(" 权限 ")]):_vm._e()],1)]}}])}),_c('CPagination',{staticStyle:{"float":"right"},attrs:{"activePage":_vm.page,"pages":_vm.pages},on:{"update:activePage":_vm.getList}})],1)],1),_c('NewUser',{attrs:{"shown":_vm.newshow},on:{"update:shown":function($event){_vm.newshow=$event},"subOk":function($event){return _vm.getList(_vm.page)}}}),_c('SelUserPerm',{attrs:{"shown":_vm.permSel,"uid":_vm.permUid},on:{"update:shown":function($event){_vm.permSel=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }